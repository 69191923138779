import * as React from "react"
import { useState } from "react"
import { Dimmer, Loader } from "semantic-ui-react"
import styled from "styled-components"
import * as tracker from "../../tracking/tracker"
import { post } from "../../../api/general"
import { PrimaryButton } from "components"
import { screenSizes } from "model"

const Container = styled.div`
  margin: auto;
  max-width: 840px;
  position: relative;
  padding: 0 20px;

  h2 {
    color: ${({ invert }) => (invert ? "white" : "inherit")};
    text-align: center;
  }

  p {
    color: ${({ invert }) => (invert ? "white" : "inherit")};
    text-align: center;
  }

  @media (max-width: ${screenSizes.tablet}px) {
    padding: 0 15px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;

  textarea,
  input {
    margin-bottom: 10px;
    border: none;
  }

  select {
    margin-bottom: 10px;
    border: none;
  }
`

const Para = styled.p`
  margin-bottom: 40px;
`

interface FormType {
  name: string
  email: string
  phone: string
  message: string
  source: string
}

const DEFAULT_FORM = {
  name: "",
  email: "",
  phone: "",
  message: "",
  source: "",
}

interface PropsType {
  invert: boolean
  children?: React.ReactNode
}

const CorporateContact = ({ invert, children }: PropsType) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSent, setIsSent] = useState<boolean>(false)
  const [form, setForm] = useState<FormType>({ ...DEFAULT_FORM })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)
    post("https://data.socialsupermarket.org/wp-json/social-supermarket/v1/contact", form)
      .then(() => {
        setIsSent(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
    tracker.corporateEnquiry(0)
  }

  const handleChange = event => {
    event.persist()
    setForm((prevForm: FormType) => {
      return {
        ...prevForm,
        [event.target.name]: event.target.value,
      }
    })
  }
  return (
    <Container invert={invert}>
      <Dimmer active={isLoading} inverted>
        <Loader inverted content="Sending.." />
      </Dimmer>
      <h2>Get In Touch</h2>
      <Para>{children}</Para>
      {isSent ? (
        <p>Thank you, we will get back to you ASAP</p>
      ) : (
        <Form onSubmit={handleSubmit} invert={invert}>
          <input onChange={handleChange} name="name" placeholder="Name" type="text" />
          <input onChange={handleChange} name="email" placeholder="Email" type="text" />
          <input onChange={handleChange} name="phone" placeholder="Phone Number" type="text" />
          <textarea onChange={handleChange} name="message" placeholder="Message" rows={10} />
          <select onChange={handleChange} name="source">
            <option disabled value="" selected>
              How did you hear about us?
            </option>
            <option>Google</option>
            <option>Facebook</option>
            <option>Instagram</option>
            <option>LinkedIn</option>
            <option>Other Social Media</option>
            <option>Word of Mouth</option>
            <option>Referred by a Friend or Colleague</option>
            <option>Blog Post</option>
            <option>Press or Newspapers</option>
          </select>
          <PrimaryButton invert>Send Enquiry</PrimaryButton>
        </Form>
      )}
    </Container>
  )
}

export default CorporateContact
