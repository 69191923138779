import * as React from "react"
import { useContext, useRef, useState } from "react"
import styled from "styled-components"
import * as tracker from "../../tracking/tracker"
import { getAllImagesSmall, isProductFullyOutOfStock } from "../../util/productUtil"
import { Popup } from "semantic-ui-react"
import GLink from "../../../gatsby/GLink"
import { rouge } from "../../constants/colors"
import SquareImage from "../image/SquareImage"
import { safeHeDecode } from "../../util/generalUtil"
import { ProductType } from "model"
import { formatPrice, getFormattedPriceRange } from "model"
import { BasketContext } from "../../context/BasketProvider"
import colorMap from "./colorMap"
import ColorSquare from "./ColorSquare"

const BrandLink = styled.div`
  letter-spacing: 0.5px;
  font-size: 0.7em;
  text-transform: uppercase;
  margin-top: 10px;

  a:hover {
    color: unset;
    text-decoration: underline !important;
  }
`
const ProductLink = styled.div<{ isHover: boolean }>`
  margin-top: 3px;
  text-decoration: ${({ isHover }) => (isHover ? "underline" : "none")};
  a:hover {
    color: unset;
    text-decoration: underline !important;
  }
`
const Container = styled.div`
  max-width: 500px;
  line-height: 20px;
  padding-bottom: 10px;
  position: relative;
`
const Price = styled.div``
const Prices = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 0.8em;
  font-weight: bold;
`
const OutOfStock = styled.p`
  color: ${rouge};
  font-size: 0.7em;
  margin-bottom: 5px;
`

const OriginalPrice = styled.div`
  color: ${rouge};
  text-decoration: line-through;
  margin-right: 10px;
`
const ImageContainer = styled.div`
  margin-bottom: 10px;
  position: relative;
`
const Colors = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  transform: scaleX(-1);
`

interface Props {
  product: ProductType
  position: number
  showImpact?: boolean
  hidePrice?: boolean
  noLink?: boolean
  hideBrand?: boolean
  collectionName?: string
  exVat?: boolean
  useImpactImage?: boolean
}

const ProductCard = ({
  product,
  position,
  showImpact,
  hidePrice,
  noLink,
  hideBrand,
  collectionName,
  exVat,
  useImpactImage,
}: Props) => {
  const { queryId } = useContext(BasketContext)
  const [isHover, setIsHover] = useState<boolean>(false)
  const trackClick = () => {
    tracker.productClick(product, position, queryId, collectionName)
  }
  const images = getAllImagesSmall(product)
  const firstImage = useImpactImage ? product.impactImage : images[0]
  const secondImage =
    product.imageBackground || product.isMerch
      ? undefined
      : useImpactImage
      ? images[1] || images[0]
      : images[1]

  const title = product.shortTitle ? product.shortTitle : product.name

  const imageBackground = product.imageBackground
    ? product.imageBackground
    : product.isMerch
    ? "#f5eee4"
    : undefined

  const handleMouseMove = () => {
    if (!isHover) {
      setIsHover(true)
    }
  }
  const handleMouseLeave = () => {
    setIsHover(false)
  }

  return (
    <Container>
      <ImageContainer onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove}>
        {!noLink ? (
          <GLink to={`/product/${product.slug}`} onClick={trackClick}>
            <SquareImage
              image={firstImage}
              secondImage={secondImage}
              sizes={"300px"}
              backgroundColor={imageBackground}
            />
          </GLink>
        ) : (
          <SquareImage
            image={firstImage}
            secondImage={secondImage}
            sizes={"300px"}
            backgroundColor={imageBackground}
          />
        )}
        {product.isMerch && product.colors?.length > 0 && (
          <Colors>
            {product.colors.map(color => (
              <ColorSquare color={color} />
            ))}
          </Colors>
        )}
      </ImageContainer>
      {!hideBrand && product.brand && !product.isMerch && (
        <BrandLink>
          <GLink to={`/brand/${product.brand.slug}`}>{safeHeDecode(product.brand.name)}</GLink>
        </BrandLink>
      )}
      <ProductLink isHover={isHover}>
        {!noLink ? (
          <GLink to={`/product/${product.slug}`} onClick={trackClick}>
            {title}
          </GLink>
        ) : (
          title
        )}
      </ProductLink>
      {isProductFullyOutOfStock(product) && <OutOfStock>Out of stock</OutOfStock>}
      {!hidePrice && (
        <Prices>
          {product.originalPrice && product.originalPrice != product.price && (
            <OriginalPrice>{formatPrice(product.originalPrice)}</OriginalPrice>
          )}
          <Price>
            {getFormattedPriceRange(product, null, null, exVat)} {exVat && <>(ex VAT)</>}
          </Price>
        </Prices>
      )}
      {showImpact && <Price>{product.productImpact}</Price>}
    </Container>
  )
}

export default ProductCard
